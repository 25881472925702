import $ from 'jquery'

// Cache selectors
const $window = $(window)
const $menuElement = $('#menu')
const $mobileMenu = $('#mobileMenu')

$window.on('scroll', function () {
  if ($window.scrollTop() > 5) {
    $menuElement.addClass('bg-white')
    if ($menuElement.hasClass('md:border-white')) {
      $menuElement.removeClass('md:border-white').addClass('md:border-blight-orange')
    }
  } else {
    $menuElement.removeClass('bg-white')
    if ($menuElement.hasClass('md:border-blight-orange')) {
      $menuElement.removeClass('md:border-blight-orange').addClass('md:border-white')
    }
  }
})

$('.menuButton').on('click', openMenu)

function openMenu() {
  if ($mobileMenu.length) {
    $mobileMenu.toggleClass('hidden')
  }

  $('body').toggleClass('overflow-hidden h-screen')
}

$('#menu-lang').hover(
  function () {
    $('#menu-lang-box').removeClass('hidden').addClass('block')
  },
  function () {
    $('#menu-lang-box').removeClass('block').addClass('hidden')
  },
)
