import $ from 'jquery'

const toggleModal = (buttonSelector, modalSelector) => {
  $(buttonSelector).click(function () {
    $('body').toggleClass('overflow-hidden w-full h-full')
    $(modalSelector).toggleClass('hidden')
  })
}

const mapping = {
  '.offer-btn': '#offer',
  '#offer-close': '#offer',
  '.callme-btn': '#callme',
  '#callme-close': '#callme',
  '.video-btn': '#video',
  '#video-close': '#video',
}

for (let button in mapping) {
  toggleModal(button, mapping[button])
}
