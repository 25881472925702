let number = 1

const options = {
  root: null, // viewport
  rootMargin: '0px',
  threshold: 0.1, // 10% elementu musí být viditelné
}

const observer = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (!entry.isIntersecting) return

    const interval = setInterval(function () {
      number++
      if (number > 19) {
        clearInterval(interval) // Zastav interval
      }
      textNode.textContent = number
    }, 150)

    observer.unobserve(entry.target) // Přestaň sledovat
  })
}, options)

const trigger = document.getElementById('numberstart')
const textNode = document.getElementById('numbercolumn__wrap')

if (trigger) {
  observer.observe(trigger)
}
